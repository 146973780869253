import React from "react"
import { Link } from "gatsby"

import Img from "gatsby-image"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-grid-system"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone,faExternalLinkAlt,  faHeart, faLifeRing, faChevronRight, faEye, faCheck } from "@fortawesome/free-solid-svg-icons"

export default class Page extends React.Component {
  render() {
    return (
      <Layout container={true} >
        <SEO title="Erstellung Ihrer neuen Webseite"></SEO>
        <meta name="robots" content="noindex, nofollow"/>
        <div className="post-heading text-center">
          <h1>Hey Domi, </h1>
          <p style={{ maxWidth: 500, margin: "auto" }}>ich möchte eine kostenlose Webseite...</p>
        </div>
          <div className="post">

        <div className="post-image">
          <picture>
            <source srcSet={require("../images/services/annoyed_cat.jpg")} type="image/jpeg" />
            <source srcSet={require("../images/services/annoyed_cat.jpg.webp")} type="image/webp" />
            <img src={require("../images/services/annoyed_cat.jpg")} />
          </picture>
        </div>

        <div class={"text"}>
          <h2>Okay, machen wir das! Folgendes wirst Du brauchen:</h2>
          <ol>
            <li><h3>Webhosting</h3><p>Deine Webseite liegt auf einem Computer, der immer eingeschaltet und mit dem Internet verbunden ist. Solche Server kann man mieten. </p></li>
            <li><h3>Inhalte</h3><p>Texte und Bilder für alle Seiten. Startseite, Über mich, Portfolio, Kontakt, Impressum und Datenschutz. Kostenlose Bilder gibts auf <a target="_blank" href={"https://pixabay.com"}><FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon> Pixabay</a>.</p></li>
            <li><h3>Zeit</h3><p>Leider kann ich nicht für alle meine Freunde Webseiten erstellen, aber ich unterstütze gerne beim Selberbauen. Und das ist wirklich einfach! Allerdings solltest Du ein paar Tage dafür einplanen.</p></li>
          </ol>
        </div>

        <div className="post-image">
          <picture>
            <source srcSet={require("../images/services/motivated_cat.jpg")} type="image/jpeg" />
            <source srcSet={require("../images/services/motivated_cat.jpg.webp")} type="image/webp" />
            <img src={require("../images/services/motivated_cat.jpg")} />
          </picture>
        </div>

        <div className={"text"}>
          <h2>Letse Go!</h2>
          <ol>
            <li><h3>Webhosting buchen</h3>
              <p>Buche einen Webhosting Vertrag. Zum Beispiel WebGo, dort bekommst Du sogar mit dem Gutscheincode "ZIEGENHAGEL" einen Rabatt.</p>
              <a className="btn btn-primary-outline" href="/support/webgo-paket-buchen" target="_blank"><FontAwesomeIcon icon={faLifeRing}></FontAwesomeIcon> Wie buche ich das Webhosting Paket?</a>
            </li>
            <li><h3>WordPress aufsetzen</h3>
            <p>Ruf mich kurz an, dann helfe ich Dir, WordPress aufzusetzen.<br/>Halte Deine WebGo Zugangsdaten bereit.</p>
            <a className="btn btn-primary-outline" href="tel:015258799871" target="_blank"><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> Dominik anrufen</a>
            </li>
            <li><h3>WordPress einrichten</h3>
              <p>Suche Dir ein WordPress Theme aus, das Dir gefällt, und spiele ein bisschen rum. Im Support Bereich gibt es auch einige WordPress Tutorials.</p>
              <a className="btn btn-primary-outline" href="/support#wordpress-guide" target="_blank"><FontAwesomeIcon icon={faLifeRing}></FontAwesomeIcon> WordPress Webseiten - Guide</a>
            </li>
            <li><h3>Denk daran, Dich über Datenschutzhinweise und Impressumpflicht zu informieren!</h3>
              <p>Schau Dich im Netz am besten nach DSGVO Datenschutzgeneratoren um. Eine tolle Seite ist zum Beispiel eRecht24.</p>
              <a className="btn btn-primary-outline" href="https://www.e-recht24.de" target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon> Zu eRecht24</a>
            </li>
            <li><h3>Karma Umverteilung</h3>
              <p>Ich freue mich sehr über Google Rezensionen.
                Wenn Du mir einen Gefallen tun möchtest, und noch kurz 1-2 Minuten Zeit hast, wäre es toll, wenn Du noch eine kleine Bewertung bei den Google Suchergebnissen dalassen könntest.<br/>
              </p>
              <a className="btn btn-danger-outline" href="https://g.page/r/CbeLFgzPbz_EEBE/review" target="_blank"><FontAwesomeIcon icon={faHeart}></FontAwesomeIcon> Schreibe mir eine Google-Rezension</a>

            </li>

          </ol>
        </div>


        <br />
        <br />
        <br />
        </div>
      </Layout >
    )
  }
}

